@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-Black.ttf") format("truetype");

  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-Bold.ttf") format("truetype");

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-BoldItalic.ttf") format("truetype");

  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-ExtraLight.ttf") format("truetype");

  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-ExtraLightItalic.ttf") format("truetype");

  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-Italic.ttf") format("truetype");

  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-Light.ttf") format("truetype");

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-LightItalic.ttf") format("truetype");

  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-Regular.ttf") format("truetype");

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-SemiBold.ttf") format("truetype");

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("TitilliumWeb-SemiBoldItalic.ttf") format("truetype");

  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
