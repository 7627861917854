footer {
  background: #3f51b5;
  color: white;
  padding: 1rem;
  margin-top: auto;
  text-align: center;
  width: 100%;
  z-index: 100;
  position: absolute;
  left: 0;
  bottom: 0;
}
div.riga {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
div.logo {
  width: 38px;
  height: 43px;
}
