/* @import "./variables.scss"; */
@import "./font/stylesheet.css";
body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fontColor);
}

* {
  font-family: "Titillium Web", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

/* Stile generale delle barre di scorrimento */
::-webkit-scrollbar {
  width: 8px; /* Larghezza della barra di scorrimento */
  height: 12px; /* Altezza della barra di scorrimento */
}

/* Stile della barra di scorrimento orizzontale */
::-webkit-scrollbar-horizontal {
  background-color: #f2f2f2; /* Colore di sfondo */
}

/* Stile della barra di scorrimento verticale */
::-webkit-scrollbar-vertical {
  background-color: #f2f2f2; /* Colore di sfondo */
}

/* Stile del pollice della barra di scorrimento (la parte trascinabile) */
::-webkit-scrollbar-thumb {
  background-color: var(--primary); /* Colore del pollice */
  margin-right: 2px;
}

/* Stile del pollice quando viene trascinato */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Colore del pollice durante il passaggio del mouse */
}

/* Stile delle frecce di scorrimento (freccia su/giù per la barra verticale, freccia sinistra/destra per la barra orizzontale) */
::-webkit-scrollbar-button {
  display: none; /* Nascondi le frecce di scorrimento */
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.full-width {
  width: 100%;
}
.full-height {
  min-height: 100vh;
}
.no-overflow {
  overflow: hidden;
}
.wrap {
  flex-wrap: wrap;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mt-05 {
  margin-bottom: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-4 {
  margin-right: 4rem;
}

.w-100 {
  width: 100%;
}

.trim-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
}
.trim-text-no-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h1,
h2,
h3 {
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;

  // margin-bottom: 1rem;
}

a {
  color: var(--primary);
  text-decoration: none;
}

.small-text {
  font-size: 0.8rem;
}

.content {
  height: calc(100vh - 80px);
  overflow-y: auto;
  scrollbar-width: auto !important;
  scrollbar-color: var(--primary) var(--fontColorLight) !important;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary) !important;
  }
  &::-webkit-scrollbar-track {
    background: var(--fontColorLight) !important;
  }

  .content-container {
    // flex-grow: 1;
    // min-width: 1600px;
    min-height: calc(100vh - 130px);
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem 2rem;
    // height: 100%;
    @media screen and (max-width: 1600px) {
      padding: 2rem 2rem;
    }

    // background-color: aqua;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editor-disabled > .ck-editor {
  pointer-events: none;
  opacity: 0.5;
}

.ck-editor__editable {
  min-height: 200px;
  min-width: 900px;
  box-shadow: none !important;
  background-color: #555;
  @media screen and (max-width: 1600px) {
    min-width: 600px;
  }
  @media screen and (max-width: 1200px) {
    min-width: 400px;
  }
  @media screen and (max-width: 900px) {
    min-width: 300px;
  }
  @media screen and (max-width: 600px) {
    min-width: 200px;
  }
}
.ck.ck-editor__top {
  box-shadow: none !important;
}

.premise-section {
  background-color: var(--premise);
  display: none;
}

span.Articolo {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

span.Comma {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

span.Lettera {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

span.Rubrica {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.vedi-modifica-btn {
  cursor: pointer;
  color: navy;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  /*     letterSpacing: 1px; */
  margin-top: 8px;
  display: none;

  &:hover {
    text-decoration: underline;
    color: var(--primary);
  }
}

.a-Articolo > div,
.html-articolo {
  margin-top: 10px;
  width: 100%;
  border-top: 2px solid var(--articolo);
  border-left: 2px solid var(--articolo);
  padding: 24px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
  position: relative;

  &.premise-section {
    background-color: var(--premise);
    display: block;
    border-top: 2px solid #32cd32;
    border-left: 2px solid #32cd32;
    margin-bottom: -10px;
  }
  // &:hover {
  //   background-color: var(--articoloLight);
  // }
}
// .a-Articolo > div {
//   pointer-events: none;
//   padding-top: 12px;
// }

.a-Articolo.changed > div {
  background-color: var(--articoloLight);

  // &:hover {
  //   background-color: var(--articoloLight);
  // }
}

.a-Comma > div {
  margin-top: 10px;
  width: 98%;
  border-top: 2px solid var(--comma);
  border-left: 2px solid var(--comma);
  padding: 40px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
  position: relative;

  &.premise-section {
    background-color: var(--premise);
    display: block;
    border-top: 2px solid #32cd32;
    border-left: 2px solid #32cd32;
    margin-bottom: -10px;
  }
  /* background-color: transparent; */
  // &:hover {
  //   background-color: var(--commaLight);
  // }
}

.changed {
  background-color: #fdfdae;

  /*   border: 1px solid #aaa !important; */
  padding: 8px;
  pointer-events: none !important;

  // TODO: capire come fare a permettere modifiche dei figli
  // provvisorio per lettere
  & > div > div > div > div > div {
    pointer-events: all !important;
  }

  & > div > .vedi-modifica-btn {
    display: block;
    pointer-events: all !important;
  }
  // border: red 2px solid !important;

  // &:hover {
  //   background-color: var(--commaLight);
  // }
}

.removed {
  background-color: #ff8383;
  /* border: 2px solid #f61e1e !important; */
  padding: 8px;
  pointer-events: none !important;
  /*  &::after {
    content: "Soppresso";
    position: absolute;
    top: 0px;
    left: 0px;

    background-color: #f61e1e;
    color: #fff;
    padding: 4px 16px 6px 16px;
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;

    border-bottom-right-radius: 6px;
  } */

  // border: red 2px solid !important;

  // &:hover {
  //   background-color: var(--commaLight);
  // }
}

.a-Lettera > div,
#xhtmlViewer div.a-Lettera {
  margin-top: 10px;
  width: 98%;
  border-top: 2px solid var(--lettera);
  border-left: 2px solid var(--lettera);
  // background-color: rgba(255, 255, 0, 0.139);
  padding: 40px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
  position: relative;
  /*  & p::before {
    content: "Lettera ";
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: var(--lettera);
    color: #fff;
    padding: 4px 0px;
    width: 200px;
  } */
  &.premise-section {
    background-color: var(--premise);
    display: block;
    border-top: 2px solid #32cd32;
    border-left: 2px solid #32cd32;
    margin-bottom: -10px;
  }
  // &:hover {
  //   background-color: var(--letteraLight);
  // }
}

.a-Lettera.changed > div {
  background-color: var(--letteraLight);

  // &:hover {
  //   background-color: var(--letteraLight);
  // }
}

.a-Numero > div {
  margin-top: 10px;
  width: 98%;
  border-top: 2px solid var(--numero);
  border-left: 2px solid var(--numero);
  padding: 40px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
  position: relative;

  &.premise-section {
    background-color: var(--premise);
    display: block;
    border-top: 2px solid #32cd32;
    border-left: 2px solid #32cd32;
    margin-bottom: -10px;
  }

  // &:hover {
  //   background-color: var(--numeroLight);
  // }
}
.a-Numero.changed > div {
  background-color: var(--numeroLight);

  // &:hover {
  //   background-color: var(--numeroLight);
  // }
}

.a-Rubrica,
#xhtmlViewer .articolo > p:first-of-type i {
  margin-top: 10px;
  width: 100%;
  border-top: 2px solid var(--rubrica);
  border-left: 2px solid var(--rubrica);
  padding: 24px 8px 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
  position: relative;
  // &:hover {
  //   background-color: var(--rubricaLight);
  // }
}
.a-Rubrica.changed {
  background-color: #fdfdae;

  // &:hover {
  //   background-color: var(--rubricaLight);
  // }
}

.paragrafo {
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
}

.Novella {
  opacity: 0.8 !important;
  pointer-events: none !important;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #ddd;
  & .a-Articolo > div {
    margin-top: 10px;
    width: 100%;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    background-color: #f0f0f020;
    padding: 0px 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    position: relative;
    background-color: #ddd;
    // &:hover {
    //   background-color: transparent;
    // }
  }

  & .a-Comma > div {
    margin-top: 10px;
    width: 98%;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    padding: 0px 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    position: relative;
    background-color: #ddd;
    // &:hover {
    //   background-color: transparent;
    // }
  }

  & .a-Lettera > div {
    margin-top: 10px;
    width: 98%;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    // background-color: rgba(255, 255, 0, 0.139);
    padding: 0px 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    position: relative;
    background-color: #ddd;
    // &:hover {
    //   background-color: transparent;
    // }
  }
  & .a-Numero > div {
    margin-top: 10px;
    width: 98%;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    background-color: #f0f0f020;
    padding: 0px 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    position: relative;
    background-color: #ddd;
    // &:hover {
    //   background-color: transparent;
    // }
  }

  & .a-Rubrica {
    margin-top: 10px;
    width: 98%;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    background-color: #f0f0f020;
    padding: 0px 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    position: relative;
    background-color: #ddd;
    // &:hover {
    //   background-color: var(--rubricaLight);
    // }
  }
}
